import cx from 'classnames'
import { PropsWithChildren } from 'react'
import { ICommonItems, TAccordionShort } from 'src/types/LandingMapTypes'
import { declOfNum } from '../../../utils/utils'
import { IconBluePin } from '../../icons/IconBluePin'
import { IconClose } from '../../icons/IconClose'
import styles from './UIPopup.module.css'

interface IProps<T> {
  items: TAccordionShort<T>[]
  onClosePopup: () => void
  isExpanded: boolean
  textVariants: string[]
}

export function UIPopup<T extends ICommonItems>({
  onClosePopup,
  items,
  isExpanded,
  children,
  textVariants,
}: PropsWithChildren<IProps<T>>) {
  return (
    <div className={cx(styles.popup, { [styles.expanded]: isExpanded })}>
      <div className={styles.popupHeader}>
        <div className={styles.popupHeaderText}>{`${items.length} ${declOfNum(
          items.length,
          textVariants,
        )} по этому адресу`}</div>
        <div className={styles.popupClose} onClick={onClosePopup}>
          <IconClose />
        </div>
      </div>
      {items.length && items[0].location ? (
        <div className={styles.popupAddressContainer}>
          <IconBluePin />
          <div className={styles.popupAddress}>{items[0].location}</div>
        </div>
      ) : null}
      <div className={styles.popupAppeals}>{children}</div>
    </div>
  )
}

UIPopup.displayName = 'UIPopup'
