import cx from 'classnames';
import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { withYMaps } from 'react-yandex-maps';
import { DEFAULT_REGION } from 'src/constants/map';
import locationIcon from '../../assets/img/landing/location.svg';
import { useFetchLocation } from '../../hooks/useFetchLocation';
import { useFetchRegions } from '../../hooks/useFetchRegions';
import { useAppContext } from '../../store/AppContenxt/AppContenxt';
import { TRegionDTO } from '../../types';
import { UIInput } from '../ui/UIInput/UIInput';
import { UIScrollBar } from '../ui/UIScrollBar/UIScrollBar';
import { RegionList } from './components/RegionList/RegionList';
import styles from './RegionSelector.module.css';

const RegionSelector: FC<any> = memo((props) => {
  const { ymaps, showLocationIcon = true, className } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [list, setList] = useState<null | TRegionDTO[]>(null);
  const regionsRef = useRef<HTMLDivElement | null>(null);
  const { store, updateStore } = useAppContext();
  const { response: regionsList, isLoading: isLoadingRegions } = useFetchRegions();
  const { response: currentRegion, isLoading: isLoadingLocation, geolocationBounds } = useFetchLocation(ymaps);

  const handleToggle = useCallback((): void => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleSearch = useCallback(
    (val: string): void => {
      setSearch(val);
      if (regionsList.length)
        setList(regionsList.filter((region) => region.name.toUpperCase().includes(val.toUpperCase())));
    },
    [regionsList],
  );

  const handleChange = useCallback(async (value: TRegionDTO) => {
    updateStore({ currentRegion: value });
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (regionsList.length && currentRegion) {
      setList(regionsList);
      updateStore({
        currentRegion: regionsList.find((region) => region.name === currentRegion) || DEFAULT_REGION,
        geolocationBounds,
      });
    }
    updateStore({ isLoadingLocation, isLoadingRegions });
  }, [regionsList, currentRegion, isLoadingLocation, isLoadingRegions]);

  useEffect(() => {
    const handleClickOutside = (event: any): void => {
      if (regionsRef?.current && !(regionsRef?.current as HTMLElement).contains(event.target)) setIsOpen(false);
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [regionsRef]);

  return (
    <div className={cx(styles.currentRegionWrapper, isOpen ? styles.currentRegionWrapper_opened : '', className)}>
      {showLocationIcon && <img src={locationIcon} alt="location" />}
      <div className={styles.currentRegion} onClick={handleToggle}>
        {store?.currentRegion?.name}
      </div>

      {isOpen && (
        <div className={styles.wrapper} ref={regionsRef}>
          <UIInput value={search} onChange={handleSearch} autoComplete="off" />
          <div className={styles.regionsListWrapper}>
            <UIScrollBar>
              <ul className={styles.regionsList}>
                {!!list?.length && <RegionList regions={list} onChange={handleChange} />}
              </ul>
            </UIScrollBar>
          </div>
        </div>
      )}
    </div>
  );
});

export default withYMaps(RegionSelector, false, ['geolocation', 'geocode']);
