import { useCallback, useEffect, useState } from 'react';

import { api } from '../api/api';

import { IRegion } from 'src/types/LandingMapTypes';

export const useFetchRegions = () => {
  const [response, setResponse] = useState<IRegion[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await api.getRegions();
      setResponse(data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return { response, isLoading };
};
