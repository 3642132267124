import { TIds } from 'src/types/LandingMapTypes';
import { getRectCoords, paramsSerializer } from 'src/utils/utils';
import { TRegionsParams } from '../types';
import { toaster as Toaster } from './toaster';

const INBOX_SERVICE = 'inbox-service';
const APPEAL_SERVICE = 'appeal-service';
const PROJECT_MANAGEMENT_SERVICE = 'project-management-service';
const CONTENT_TYPE = { 'Content-Type': 'application/json' };

class Api {
  private doRequest(
    method: any,
    url: any,
    request: any,
    contentType: string = 'application/json',
    headerHash?: any,
    esiaToken?: string,
  ) {
    // eslint-disable-next-line no-undef
    const config: RequestInit = {
      method,
      credentials: 'same-origin',
      headers: undefined,
    };

    if (contentType !== 'multipart/form-data') {
      config.headers = { 'Content-Type': contentType };
    }

    if (headerHash && config.headers) {
      // @ts-ignore
      config.headers['X-VERIFICATION-HASH'] = headerHash;
    }

    if (esiaToken && config.headers) {
      // @ts-ignore
      config.headers['X-ESIA-Token'] = esiaToken;
    }

    if (request) {
      config.body = request;
    }

    return fetch(`/${url}`, config)
      .then((response) => {
        if (!response.ok && response.status !== 404) {
          response.json().then((errorJson) => {
            const fields = errorJson.fields ? Object.values(errorJson.fields) : [];
            const messages = errorJson.messages || [];
            const errorsLength = [...fields, ...messages].length;
            const message = errorsLength ? [...fields, ...messages].toString() : errorJson.message || '';

            if (message) {
              Toaster.error(message);
            }
          });

          throw new Error(response.statusText);
        }

        return response;
      })
      .then((response) => {
        const type = response.headers.get('content-type');

        if (!type) {
          console.error('Отстутствует тип content-type для запроса');
          return true;
        }

        if (type.startsWith('application/json')) {
          return response.json();
        }
        if (type.startsWith('application/octet-stream')) {
          return response.blob();
        }
        if (type.startsWith('text/plain')) {
          return response.text();
        }

        throw Error(`Wrong content-type: "${type}"`);
      });
  }

  private doGet(url: string, request?: any, esiaToken?: string) {
    return this.doRequest('get', url, request, undefined, undefined, esiaToken);
  }

  private doPost(url: string, request?: any, contentType = 'application/json', headerHash = null) {
    return this.doRequest('post', url, request, contentType, headerHash);
  }

  /**
   * Авторизация, получение "авторизационной" ссылки
   */
  public getEsiaAuth() {
    const location = window.location.href;
    return this.doGet(`user-service/esia/esia-auth/${location ? `?returnUrl=${location}` : ''}`);
  }

  /**
   * Получение токена по авторизационной ссылке
   */
  public getEsiaToken(params: { code: string; state: string }) {
    return this.doGet(`user-service/esia/esia-token?code=${params.code}&state=${params.state}`);
  }

  /**
   * Разавторизация, получение "разавторизационной" ссылки
   */
  public getEsiaLogout() {
    const location = window.location.href;
    return this.doGet(`user-service/esia/logout-url/${location ? `?returnUrl=${location}` : ''}`);
  }

  public getEsiaUser(params: { esiaToken: string }) {
    return this.doGet(`user-service/esia/get-user-info`, undefined, params.esiaToken);
  }

  public getStatistic() {
    return this.doGet(`${INBOX_SERVICE}/landing/statistic`);
  }

  /**
   * Топ проблем (5шт)
   */
  public getSubjectTop() {
    return this.doGet(`${INBOX_SERVICE}/landing/subject-top`);
  }

  /**
   * Топ регионов по решению проблем
   */
  public getRegionTop({ size }: { size: number }) {
    const sizeQuery = size ? `&size=${size}` : '';
    const hasQuery = sizeQuery ? '?' : '';

    return this.doGet(`${INBOX_SERVICE}/landing/region-top${hasQuery}${sizeQuery}`);
  }

  /**
   * Все регионы
   */
  public getRegions() {
    return this.doGet(`${INBOX_SERVICE}/regions/all`);
  }

  /**
   * Данные для карусели
   */
  public getAppealsTop({ regionId, size }: TRegionsParams) {
    const regionIdQuery = regionId ? `&regionId=${regionId}` : '';
    const sizeQuery = size ? `&size=${size}` : '';
    const hasQuery = regionIdQuery || sizeQuery ? '?' : '';

    return this.doGet(`${INBOX_SERVICE}/landing/appeals-top${hasQuery}${regionIdQuery}${sizeQuery}`);
  }

  // Запрос обращений для карты

  public getAppealsMap = (coords: number[][]) =>
    this.doGet(
      `${APPEAL_SERVICE}/landing/appeals/coordinates?${paramsSerializer<Record<string, number>>(
        getRectCoords(coords),
      )}`,
    );

  public getProjectsMap = (coords: number[][]) =>
    this.doGet(
      `${PROJECT_MANAGEMENT_SERVICE}/landing/object-on-map?${paramsSerializer<Record<string, number>>(
        getRectCoords(coords),
      )}`,
    );

  public async getRegionAppeals(url: string, coords: string[], region: number[], createdAtInterval: any) {
    const result = [];

    const requestBody = {
      createdAtInterval,
      leftTopCorner: {
        lat: coords[0][0],
        lon: coords[0][1],
      },
      regionIds: region,
      rightBottomCorner: {
        lat: coords[1][0],
        lon: coords[1][1],
      },
      subjectId: 0,
      status: 'IN_PROGRESS',
    };

    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    };

    try {
      const res = await fetch(url, params);
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      const data = await res.json();
      result.push(...data);
      const res2 = await fetch(url, { ...params, body: JSON.stringify({ ...requestBody, status: 'ANSWER_RECEIVED' }) });
      if (!res2.ok) {
        throw new Error(res2.statusText);
      }
      const data2 = await res2.json();
      result.push(...data2);
    } catch (e) {
      console.log(e);
    }
    return result;
  }

  public getRegionAppealsByIds(ids: number[]) {
    return fetch(`/api-service/appeals-mobile-api/public-by-ids`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ids,
        size: 1000,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .catch((error) => console.log(error));
  }

  public getPublicAppealStepsById(id: number) {
    return this.doGet(`api-service/appeals-mobile-api/public-appeal-steps-by-id/${id}`);
  }

  public getDetailedAppeal(id: number) {
    return this.doGet(`${INBOX_SERVICE}/landing/appeal-detailed?id=${id}`);
  }

  public getAppealsByIds(ids: number[]) {
    return this.doGet(`${INBOX_SERVICE}/landing/short-appeals-by-ids?ids=${ids.join('&ids=')}`);
  }

  public getPollsRequest(url: string, contentType?: boolean) {
    return fetch(url, { headers: contentType ? CONTENT_TYPE : undefined })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .catch((error) => console.log(error));
  }

  public getAppealsByRepeatIds = (params: TIds) =>
    this.doGet(`${APPEAL_SERVICE}/landing/appeals?${paramsSerializer<TIds>(params)}`);

  public getProjectsByRepeatIds = ({ ids }: TIds) =>
    this.doGet(`${PROJECT_MANAGEMENT_SERVICE}/landing/project-details?${paramsSerializer<TIds>({ projectIds: ids })}`);
}

export const api = new Api();
