import cx from 'classnames';
import { FC } from 'react';
import styles from '../LandingMapPopup.module.css';
import Row from '../components/Row';

export const AppealHeaderContent: FC<any> = (item) => {
  const inProcess = item.stage?.toLowerCase() === 'в работе';
  return (
    <>
      <div className={cx(styles.contentPopupTitle, styles.row)}>{item.subjectName}</div>
      <div className={cx(styles.contentPopupStatus, styles.row)}>
        <span
          className={cx(
            styles.contentPopupStatusIndicator,
            inProcess ? styles.contentPopupStatusIndicatorInProcess : styles.contentPopupStatusIndicatorCompleted,
          )}
        />
        {item.stage}
      </div>
    </>
  );
};

export const AppealBodyContent: FC<any> = ({ description, stage, answer }) => {
  const inProcess = stage?.toLowerCase() === 'в работе';
  return (
    <>
      <Row prop={'Обращение:'} value={description} />
      {!inProcess && answer && <Row prop={'Ответ:'} value={<div dangerouslySetInnerHTML={{ __html: answer }} />} />}
    </>
  );
};
