import { YMapsApi } from 'react-yandex-maps';
import { EEntityType } from 'src/types/LandingMapTypes';
import { ALL_LAYERS, ONLY_APPEALS, ONLY_PROJECTS } from './LayerControlConst';
import { ELayerType } from './LayerControlEnum';

export const createLayerControl = (
  ymaps: YMapsApi,
  layerState: any,
  setLayerState: any,
  clearPrevSelectedObjectsOnMap: (type?: EEntityType) => void,
) => {
  const ListBoxLayout = ymaps.templateLayoutFactory.createClass(
    `
    <button 
      class="pos-layer-btn"
      data-toggle="dropdown">
      <svg 
        width="19"
        height="19"
        viewBox="0 0 19 19" 
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path 
          fill-rule="evenodd" 
          clip-rule="evenodd" 
          d="M16.2207 5.71428L9.89856 1.89171C9.41525 1.59948 8.80702 1.5995 8.32372 1.89175L2.00269 5.71405L8.32377 9.53655C8.80707 9.82881 9.4153 9.82884 9.89862 9.53662L16.2207 5.71428ZM10.686 0.619721C9.71933 0.0352625 8.50287 0.0352906 7.53627 0.619792L0.514099 4.86607C-0.127086 5.25379 -0.127098 6.17426 0.514079 6.562L7.53629 10.8085C8.50288 11.393 9.71934 11.3931 10.686 10.8086L17.7093 6.5623C18.3506 6.17459 18.3506 5.25402 17.7093 4.8663L10.686 0.619721ZM9.21025 13.5422L17.3583 8.98148L18.1234 10.2566L9.97529 14.8173C9.42696 15.1242 8.75102 15.1264 8.20056 14.823L0.105057 10.3618L0.861209 9.08176L8.95671 13.543C9.03535 13.5863 9.13191 13.586 9.21025 13.5422ZM17.3343 12.5445L9.20027 17.2418C9.12188 17.2871 9.02439 17.2882 8.94486 17.2449L0.856153 12.8322L0.106208 14.1158L8.19491 18.5285C8.7516 18.8322 9.43407 18.8241 9.98282 18.5072L18.1168 13.8098L17.3343 12.5445Z" 
          fill="{% if state.selectOne %}#ff8218{% else %}#000000{% endif %}"/>
      </svg>
    </button>
    <ul 
      class="pos-layer-menu"
      role="menu" 
      aria-labelledby="dropdownMenu"
      style="display: {% if state.expanded %}block{% else %}none{% endif %};">
    </ul>
  `,
    {
      build: function () {
        ListBoxLayout.superclass.build.call(this);
        this.childContainerElement = document.querySelector('.pos-layer-menu');
        this.events.fire('childcontainerchange', {
          newChildContainerElement: this.childContainerElement,
          oldChildContainerElement: null,
        });
      },
      getChildContainerElement: function () {
        return this.childContainerElement;
      },
      clear: function () {
        this.events.fire('childcontainerchange', {
          newChildContainerElement: null,
          oldChildContainerElement: this.childContainerElement,
        });
        this.childContainerElement = null;
        ListBoxLayout.superclass.clear.call(this);
      },
    },
  );

  const ListBoxItemLayout = ymaps.templateLayoutFactory.createClass(`
    <li class="pos-layer-menu__item">
      <label class="pos-layer-checkbox">
        <input 
          name="layer-name" 
          class="pos-layer-checkbox__input"
          type="radio" 
          {% if state.checked %}checked{% endif %}>
        </input>
        <span class="pos-layer-checkbox__custom"></span>
        <span class="pos-layer-checkbox__label">
          {{data.content}}
        </span>
      </label>
    </li>
  `);

  const listBoxItems = [
    {
      data: {
        content: ALL_LAYERS,
        type: ELayerType.all,
      },
      state: {
        checked: layerState === ELayerType.all,
      },
    },
    {
      data: {
        content: ONLY_APPEALS,
        type: ELayerType.appeals,
      },
      state: {
        checked: layerState === ELayerType.appeals,
      },
    },
    {
      data: {
        content: ONLY_PROJECTS,
        type: ELayerType.projects,
      },
      state: {
        checked: layerState === ELayerType.projects,
      },
    },
  ].map((item) => new ymaps.control.ListBoxItem(item)) as any[];

  const listBox = new ymaps.control.ListBox({
    items: listBoxItems,
    data: {
      title: '',
    },
    state: {
      selectOne: listBoxItems
        .filter((item) => item.data.get('type') !== ELayerType.all)
        .some((item) => item.state.get('checked')),
    },
    options: {
      layout: ListBoxLayout,
      itemLayout: ListBoxItemLayout,
      size: 'small',
      position: {
        bottom: 186,
        right: 37,
      },
    },
  });

  listBox.events.add('click', function (ev: ymaps.Event) {
    const item = ev.get<any>('target');

    if (item != listBox) {
      const currentItem = item.data.get('type');
      listBoxItems.forEach((listItem) => listItem.state.set('checked', listItem.data.get('type') === currentItem));
      listBox.state.set('selectOne', currentItem !== ELayerType.all);
      clearPrevSelectedObjectsOnMap();
      setLayerState(currentItem);
    }
  });

  return listBox;
};
