import { useEffect, useState } from 'react';
import { YMapsApi } from 'react-yandex-maps';
import { DEFAULT_MAP_STATE, DEFAULT_REGION } from 'src/constants/map';

export const useFetchLocation = (ymaps: YMapsApi) => {
  const [response, setResponse] = useState<string>(DEFAULT_REGION.name);
  const [geolocationBounds, setGeoLocationBounds] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const data = await ymaps.geolocation.get();
        const regionName = data?.geoObjects?.get(0).getAdministrativeAreas()[0] || '';
        const regions = await ymaps.geocode(regionName);
        const regionBounds = regions.geoObjects.get(0).properties.get('boundedBy');
        setGeoLocationBounds(regionBounds || DEFAULT_MAP_STATE.bounds);
        setResponse(regionName);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };
    if (ymaps) {
      fetchData();
    }
  }, [ymaps]);

  return { response, geolocationBounds, isLoading };
};
