import SendMessageButton from '../SendMessageButton/SendMessageButton';
import { LandingMapWithYMaps as LandingMap } from './components/LandingMap/LandingMap';
import './landingAppealsMap.css';

const LandingAppealsMap = () => {
  return (
    <section className="wrapper">
      <div className="container">
        <div className="map-container">
          <LandingMap />
        </div>
        <div className="button-container">
          <SendMessageButton />
        </div>
      </div>
    </section>
  );
};

export default LandingAppealsMap;
