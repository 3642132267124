import benefit from 'src/assets/img/new-landing/carousel/benefit_icon.svg'
import call from 'src/assets/img/new-landing/carousel/call_icon.svg'
import doctor from 'src/assets/img/new-landing/carousel/doctor_icon.svg'
import drug from 'src/assets/img/new-landing/carousel/drug_icon.svg'
import food from 'src/assets/img/new-landing/carousel/food_icon.svg'
import payments_med from 'src/assets/img/new-landing/carousel/payment_med_icon.svg'
import problem from 'src/assets/img/new-landing/carousel/problem_icon.svg'
import pushkin_card from 'src/assets/img/new-landing/carousel/pushkin_icon.svg'
import education from 'src/assets/img/new-landing/carousel/school_icon.svg'
import sport from 'src/assets/img/new-landing/carousel/sport.svg'
import virus from 'src/assets/img/new-landing/carousel/virus_icon.svg'
import fkgs from 'src/assets/img/new-landing/carousel/golosovanie_blagoustroistvo.svg'

import {
  GOSUSLUGI_BENEFIT_LINK,
  GOSUSLUGI_CALLS_LINK,
  GOSUSLUGI_DOCTOR_LINK,
  GOSUSLUGI_DRUG_LINK,
  GOSUSLUGI_EDUCATION_LINK,
  GOSUSLUGI_MINZDRAV_LINK,
  GOSUSLUGI_PAYMENTS_MED_LINK,
  GOSUSLUGI_PUSHKIN_CARD_LINK,
  GOSUSLUGI_SPORT_LINK,
  GOSUSLUGI_VEDOMSTVO_LINK,
  GOSUSLUGI_FKGS_LINK,
} from '../../../../constants/links'

import { getPOSLink } from 'src/utils/redirect'

export const SLIDES = [
  {
    id: 14,
    img: pushkin_card,
    to: GOSUSLUGI_PUSHKIN_CARD_LINK,
    title:
      'Возникли проблемы\nс получением\nили использованием\nПушкинской карты?'
  },
  {
    id: 15,
    img: fkgs,
    to: GOSUSLUGI_FKGS_LINK,
    title: 'Комфортная городская\n среда',
    text: 'Голосуйте за территории\n для благоустройства',
    cardBackgroundColor: 'linear-gradient(313deg, #8849ee 0%, #d052eb 100%)',
    textColorLight: true
  },
  {
    id: 3,
    img: food,
    to: GOSUSLUGI_VEDOMSTVO_LINK,
    title: 'Питание в школах ',
    text: 'Если нарушены правила питания или если обед холодный'
  },
  {
    id: 1,
    img: virus,
    to: GOSUSLUGI_MINZDRAV_LINK,
    title: 'Вакцинация и лечение от коронавируса ',
    text: 'Если трудно записаться на прививку, вызвать врача или получить лекарства'
  },
  {
    id: 2,
    img: problem,
    to: getPOSLink(),
    title: 'Сообщить о проблеме',
    text: 'Сообщите о ямах, мусоре, плохом освещении и других проблемах'
  },
  {
    id: 4,
    img: sport,
    to: GOSUSLUGI_SPORT_LINK,
    title:
      'Есть жалобы на спортивное учреждение, в котором\n обучается ребенок?',
    text: 'Сообщите о проблеме'
  },
  {
    id: 5,
    img: call,
    to: GOSUSLUGI_CALLS_LINK,
    title: 'Не можете дозвониться в службу 122, скорую или поликлинику?',
    subTitle: 'Сообщите о проблеме',
    className: 'handset-block-content'
  },
  // Task/161585
  // {
  //   id: 6,
  //   img: university,
  //   to: GOSUSLUGI_UNIVERSITY_LINK,
  //   title: 'Поступите в вуз онлайн',
  //   text: 'Без бумажных заявлений и очередей',
  // },
  {
    id: 7,
    img: doctor,
    to: GOSUSLUGI_DOCTOR_LINK,
    title: 'Не смогли записаться к врачу через Госуслуги?',
    text: 'Сообщите о проблеме'
  },
  {
    id: 8,
    img: drug,
    to: GOSUSLUGI_DRUG_LINK,
    title: 'Не смогли получить льготное лекарство?',
    text: 'Сообщите о проблеме'
  },
  {
    id: 9,
    img: benefit,
    to: GOSUSLUGI_BENEFIT_LINK,
    title: 'Не получили выплаты и льготы для военнослужащих и их семей?',
    text: 'Сообщите о проблеме'
  },
  // Task/161585
  // {
  //   id: 10,
  //   img: military,
  //   to: GOSUSLUGI_MILITARY_LINK,
  //   title: 'Обращение о несогласии\nс решением при поступлении\nна военную службу\nпо контракту',
  // },
  {
    id: 11,
    img: education,
    to: GOSUSLUGI_EDUCATION_LINK,
    title:
      'Проблемы c отображением\nсведений об образовании\nили об индивидуальных\nдостижениях\nна портале Госуслуг'
  },
  // Task/161585
  // {
  //   id: 12,
  //   img: college,
  //   to: GOSUSLUGI_COLLEGE_LINK,
  //   title: 'Возникли проблемы\nс поступлением в колледж\nили техникум онлайн?',
  // },
  {
    id: 13,
    img: payments_med,
    to: GOSUSLUGI_PAYMENTS_MED_LINK,
    title:
      'Проблемы с получением\nсоциальной выплаты для\nмедицинских работников?'
  }
]
