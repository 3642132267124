import { useCallback, useEffect, useState } from 'react';
import { AccordionItem } from 'src/components/ui/UIAccordion/UIAccordion';
import { UIPopup } from 'src/components/ui/UIPopup/UIPopup';
import { UISpinner } from 'src/components/ui/UISpinner/UISpinner';
import { ICommonItems, IRenderType, TAccordionShort, TProjectObject } from 'src/types/LandingMapTypes';
import styles from './LandingMapPopup.module.css';
interface ILandingMapPopupProps<T> extends IRenderType<T> {
  items: T[];
  onClosePopup: () => void;
  textVariants: string[];
  isLoading: boolean;
  objectId?: number;
  hasObjects?: boolean;
  setObjects?: (objs: TProjectObject[]) => void;
}

function LandingMapPopup<T extends ICommonItems>({
  items: initialItems,
  onClosePopup,
  renderAccoHeaderContent,
  renderAccoBodyContent,
  textVariants,
  isLoading,
  objectId,
  hasObjects,
  setObjects,
}: ILandingMapPopupProps<T>) {
  const [items, setItems] = useState<TAccordionShort<T>[]>(
    initialItems.map((item: any) => ({ ...item, isOpen: false })),
  );
  const isExpanded = items.some((item) => item.isOpen);

  const toggleAccordion = useCallback(
    (id: number): void => {
      const newItems = items.map((item) => ({
        ...item,
        isOpen: item.id === id ? !item.isOpen : false,
      }));
      setItems(newItems);
    },
    [items],
  );

  useEffect(() => {
    setItems(initialItems.map((item: any) => ({ ...item, isOpen: false })));
  }, [initialItems]);

  return (
    <UIPopup<T> onClosePopup={onClosePopup} items={items} isExpanded={isExpanded} textVariants={textVariants}>
      {isLoading ? (
        <div className={styles.loaderWrap}>
          <UISpinner isLoading={isLoading} />
        </div>
      ) : (
        items.map((item) => (
          <AccordionItem<T>
            hasObjects={hasObjects}
            setObjects={setObjects}
            objectId={objectId}
            key={item.id}
            item={item}
            onToggle={toggleAccordion}
            renderAccoHeaderContent={renderAccoHeaderContent}
            renderAccoBodyContent={renderAccoBodyContent}
          />
        ))
      )}
    </UIPopup>
  );
}

export default LandingMapPopup;
