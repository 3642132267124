import { MapState } from 'react-yandex-maps';
import { AnyObject, IRegion } from 'src/types/LandingMapTypes';

export const APPEAL_TEXT_VARIANTS = ['обращение', 'обращения', 'обращений'];
export const PROJECT_TEXT_VARIANTS = ['проект', 'проекта', 'проектов'];

export const FETCH_REGIONS_CONFIG = [
  {
    location: 'RU',
    lang: 'ru',
    quality: 0,
  },
  {
    location: 'UA',
    lang: 'ru',
    quality: 0,
  },
];

export const ZOOM_CONTROL_OPTIONS = {
  options: {
    size: 'small',
    position: {
      bottom: 90,
      right: 50,
    },
  },
};

export const GEO_LOCATION_CONTROL_OPTIONS = {
  options: {
    position: {
      bottom: 40,
      right: 37,
    },
  },
};

// границы Москвы
export const DEFAULT_MAP_STATE: MapState = {
  bounds: [
    [50.1, 30.2],
    [60.3, 20.4],
  ],
};

export const DEFAULT_SELECTED_GEOMETRY = [
  [0, 0],
  [0, 0],
];

export const MAP_HEIGHT = 430;

// границы РФ, ограничения видимой части карт number[][]
export const MAP_RESTRICTIONS: AnyObject = [
  [30.500228397073368, -32.604046359778266],
  [72.67631915063065, -176.0415463597783],
];

export const DEFAULT_REGION: IRegion = {
  coordinates: '55.755864,37.617698',
  id: 80,
  name: 'Москва',
  okato: '45',
};

export const mapOptions: AnyObject = {
  restrictMapArea: MAP_RESTRICTIONS,
  yandexMapDisablePoiInteractivity: true,
  maxZoom: 21,
};

export const mapStyles = {
  width: '100%',
  height: MAP_HEIGHT,
  position: 'relative',
  boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
  borderRadius: '12px',
};

export const clusterOptions = {
  clusterize: true,
  gridSize: 256,
};

export const clusterNumbers = [10, 100, 1000, 10000];
export const iconLayout = 'default#image';
