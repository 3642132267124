import cx from 'classnames';
import styles from './ShowObjectsButton.module.css';
const ShowObjectsButton = ({ setIsShow, isShow }: any) => {
  return (
    <button
      className={cx(styles.btn, {
        [styles.back]: isShow,
      })}
      onClick={() => setIsShow((s: any) => !s)}>
      {isShow ? 'Вернуться' : 'Показать объекты'}
    </button>
  );
};

export default ShowObjectsButton;
