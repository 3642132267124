export const exceptionsRegions = {
  'UA-09': 'Луганская Народная Республика',
  'UA-14': 'Донецкая Народная Республика',
  'UA-23': 'Запорожская область',
  'UA-65': 'Херсонская область',
} as const;

export const exceptionsRegionsNames = Object.values(
  exceptionsRegions,
) as (typeof exceptionsRegions)[keyof typeof exceptionsRegions][];
export const exceptionsRegionsIso3166 = Object.keys(exceptionsRegions) as (keyof typeof exceptionsRegions)[];
