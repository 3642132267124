import React, { useContext, useState } from 'react';
import { DEFAULT_REGION } from 'src/constants/map';
import { TCoords } from 'src/types/LandingMapTypes';
import { TRegionDTO, TUser } from '../../types';

export interface IAppContextProps {
  children: React.ReactNode;
}

export interface IStore {
  user?: TUser;
  userFullName: string;
  isGlobalLoading: boolean;
  isLoadingLocation: boolean;
  isLoadingRegions: boolean;
  currentRegion: TRegionDTO;
  geolocationBounds: TCoords;
}

export interface IAppContextValue {
  store: IStore;
  updateStore: (data: { [key: string]: any }) => unknown;
}

const INITIAL_STATE: IStore = {
  user: {} as TUser,
  userFullName: '',
  isGlobalLoading: false,
  isLoadingLocation: false,
  isLoadingRegions: false,
  currentRegion: DEFAULT_REGION,
  geolocationBounds: [],
};

// инициализируем контекст
const GlobalContext = React.createContext({} as IAppContextValue);

// кастомный хук для для обращения к контексту в компонентах
export const useAppContext = () => {
  return useContext(GlobalContext);
};

export const AppContext = ({ children }: IAppContextProps) => {
  const [store, setStore] = useState(INITIAL_STATE);

  const updateStore = (data: { [key: string]: any }) => {
    Object.keys(data).forEach((key) => {
      setStore((prevContext) => ({ ...prevContext, [key]: data[key] }));
    });
  };

  return (
    <GlobalContext.Provider
      value={
        {
          store,
          updateStore,
        } as IAppContextValue
      }>
      {children}
    </GlobalContext.Provider>
  );
};
