import React, { FC, memo } from 'react';
import cx from 'classnames';

import styles from './UISpinner.module.css';

interface IUISpinnerProps {
  isLoading: boolean | undefined;
  isGlobal?: boolean;
}

export const UISpinner: FC<IUISpinnerProps> = ({ isLoading = true, isGlobal = false }) => (
  isLoading ? (
    <div className={cx(styles.spinner, {[styles.spinnerGlobal]: isGlobal})}>
      <div className={styles.ldsDefault}>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
      </div>
    </div>
  ) : null
);
