import cx from 'classnames';
import { FC } from 'react';
import styles from './Row.module.css';

const Row: FC<any> = ({ prop, value, propBold, dark }) => (
  <div className={cx(styles.row)}>
    <div className={cx(styles.prop, { [styles.propBold]: propBold, [styles.dark]: dark })}>{prop}</div>
    <div className={styles.value}>{value}</div>
  </div>
);

export default Row;
